import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function defaultTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark
  return (

    <Layout>

      <div className="banner">
        <div className="bannerContent center">
          <h1>{frontmatter.bannerTitle}</h1>
          </div>
      </div>

      <div className="container">
        <div className="ginecologoOnline">
          <div className="GOSidebar">
            <ul className="list-unstyled">
              {frontmatter.section ? frontmatter.section.map((item) => {
                return (
                  <li><a href={'#ID' + item.id}>{item.title}</a></li>
                )
              }) : ''}
            </ul>
            <div className="redazione">
              <h6>Redazione:</h6>
              <p>Redatto da: {frontmatter.publisher}</p>
              <p><b>Data ultima modifica:</b> {frontmatter.date}</p>
            </div>

          </div>


          <div className="GOMainContent">
            <h2 className="mb-4">{frontmatter.title}</h2>
            {frontmatter.section.map((item) => {
              return (
                <div id={'ID' + item.id}>
                  <h3>{item.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                  {item.button ?
                    <p className="mt-4"><a href={item.link} className="btn py-3 px-5 btn-theme">{item.buttonLabel}</a></p>
                    : ''
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        bannerTitle
        title
        metaTitle
        metaDescription
        section {
          id
          title
          body
        }
      }
    }
}
` 
